.main_header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 8.5vh;
    position: fixed;
    color: var(--white_text);
    margin-top: 1%;
    top: 0;
    z-index: 99;

    nav {
        display: flex;
        flex-wrap: wrap;
        width: 75%;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        border-radius: 30px;
        background-color: var(--navbar);
        align-content: center;
        z-index: 100;

        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.359);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

        .container_01 {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            padding: 10px 0;
            width: 25%;
            margin-left: 2%;

            a {
                background-image: url(../components/img/neodev3d_logo.webp);
                width: 60px;
                max-width: 70px;
                height: 60px;
                max-height: 70px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
                margin-left: 2%;
                transition: 0.5s;

                &:hover {
                    transition: 0.4s;
                    scale: 1.05;
                }
            }

            h1 {
                font-size: calc(2.4vw / 2 + 2.4vh / 2);
                font-weight: 600;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .container_02 {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            padding: 10px 0;
            width: 68%;
            color: var(--white_text);
            font-size: calc(2vw / 2 + 2vh / 2);
            transition: max-height 0.3s ease, background-color 0.3s ease;
            overflow: hidden;
            margin-right: 4%;

            button {
                border: none;
                cursor: pointer;
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 25px;
                transition: all 0.3s ease;
                color: var(--white_text);
                background-color: #00000000;
                font-size: calc(2vw / 2 + 2vh / 2);
                font-weight: 600;
                width: 20%;

                &:hover {
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.361);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                }
            }

            button:nth-child(7) {
                background-color: #00000070;

                box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.544);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);

                &:hover {
                    background-color: #000000c2;
                }
            }

            p{
                margin-bottom: 1%;}

            &.open {
                flex-direction: column;
                position: absolute;
                top: 100%;
                right: 0;
                width: 65%;
                max-height: 500px; // Adjust as needed for the number of items
                background-color: #023143d0;
                border-radius: 30px 30px 30px 30px;

                button {
                    width: 100%;
                    margin: 5px 0;
                }

                p {
                    display: none; // Hide separators on mobile
                }
            }
        }

        .container_02 open{
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.359);
            backdrop-filter: blur(205px);
            -webkit-backdrop-filter: blur(5px);
        }

        .menu_toggle {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            height: 25px;
            cursor: pointer;
            margin-right: 4%;

            .menu_icon {
                width: 25px;
                height: 3px;
                background-color: var(--white_text);
                border-radius: 2px;
            }
        }
    }

    .language_selector{
        position: absolute;
        top: 0;
        right: 0;
        width: 180px;
        padding: 0.2%;
        padding-right: 1%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-left: 1%;

        button{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            min-width: 70px;
            min-height: 50px;
            border-radius: 15px;
            cursor: pointer;
            border: none;
            opacity: 0.4;

            filter: brightness(0.7);

            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.361);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);

            &:hover{
                box-shadow: inset 0 4px 30px rgba(0, 0, 0, 0.361);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                opacity: 1;
                scale: 1.06;

                filter: brightness(1);
            }
        }

        #en_btn{
            background-image: url(../components/img/us.svg);
            filter: brightness(1);


        }
        #fr_btn{
            background-image: url(../components/img/fr.svg);
        }
    }

    .language_selector_mobile{
        width: 90%;
        padding: 0.2%;
        padding-right: 1%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-left: 1%;

        button{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            max-width: 80px;
            min-height: 45px;
            border-radius: 1px;
            cursor: pointer;
            border: none;
            opacity: 0.4;

            filter: brightness(0.7);

            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.361);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);

            &:hover{
                box-shadow: inset 0 4px 30px rgba(0, 0, 0, 0.361);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                opacity: 1;
                scale: 1.06;

                filter: brightness(1);
            }
        }

        #en_btn{
            background-image: url(../components/img/us.svg);
            filter: brightness(1);


        }
        #fr_btn{
            background-image: url(../components/img/fr.svg);
        }
    }

    .language_selector button.is_active {
        opacity: 1;
        scale: 1.04;
        filter: brightness(0.9);
    }
    .language_selector_mobile button.is_active {
        opacity: 1;
        scale: 1.04;
    }

    button:disabled {
        display: none;
      }
      

    @media (max-width: 1500px) {
        .language_selector{
            width: 120px;
    
            button{
                min-width: 50px;
                min-height: 40px;
            }
        }
    }

    @media (max-width: 1200px) {
        .language_selector{
            flex-direction: column;
            gap: 10px;
        }
    }

}



// Mobile styles
@media (max-width: 768px) {
    .language_selector {
        display: none;
    }
    .main_header nav {
        width: 90%;
        .container_01 {
            width: 70%;
            h1{
                font-size: calc(3.5vw / 2 + 3.5vh / 2);
            }
        }
        .container_02 {
            max-height: 0;
            width: 0%;

            p{
                display: none;
            }

            button {
                font-size: calc(3vw / 2 + 3vh / 2);
            }
        }

        .menu_toggle {
            display: flex;
        }
    }

    .container_02 {
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.516);
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(5px);

            button {
                box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.516);
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(5px);
            }

            #contact_btn {
                background-color: #00000077;
                box-shadow: inset 0 4px 10px rgb(0, 0, 0);
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(5px);
            }
    }
}


