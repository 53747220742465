.printing_section {
    padding: 40px;
    background-color: #1b2b42af;  // Couleur de fond sombre pour la section
    color: #ffffff;  // Texte en blanc pour contraste avec le fond
    text-align: center;
    width: 80%;
    border-radius: 20px;
  
    .printing_title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 20px;
      color: #ffffff;  // Titre en blanc
    }
  
    .printing_txt {
      font-size: 1.2rem;
      line-height: 1.6;
      margin-bottom: 40px;
      color: #b0bec5;  // Texte secondaire en gris clair pour un bon contraste
    }
  
    .printing_our_reals {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 20px;
      color: #ffffff;  // Sous-titre en blanc
    }
  
    .real_container {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-bottom: 40px;
  
      .real_img {
        width: 200px;
        height: auto;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);  // Effet d'ombre sur les images
        transition: transform 0.3s ease;
  
        &:hover {
          transform: scale(1.05);  // Légère augmentation de la taille au hover
        }
      }
    }
  
    .contact_button {
      padding: 12px 24px;
      font-size: 1.1rem;
      background-color: #007bff;  // Couleur bleue pour correspondre au thème
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3;  // Bleu plus foncé au hover
      }
    }
  }
  