// src/components/Services.scss
.services {
    text-align: center;
    margin: 6%;
    width: 60%;

    h2 {
      margin-bottom: 20px;
      font-size: calc(2.5vh/2 + 2.5vw/2);
      font-weight: 600;
      color: rgb(255, 255, 255);
    }

    .services_box{
        background-color: #29374221;
        border-radius: 25px;
        box-shadow: 0 8px 40px rgba(0, 0, 0, 0.438);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 2%;

        .services_container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-bottom: 20px;
      
            .service-item {
              background-color: #010c112f;
              border-radius: 18px;
              text-align: center;
              margin: 10px;
              flex: 1 1 30%;
              height: 200px;
              max-width: 335px;
              box-shadow: inset 0 4px 30px rgba(0, 0, 0, 0.359);
              backdrop-filter: blur(5px);
              -webkit-backdrop-filter: blur(5px);
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;

              i {
                /*   positioning */
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    left: 0;

                /*   gradient   */
                    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.03) 1%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.85) 50%, rgba(255,255,255,0.85) 70%, rgba(255,255,255,0.85) 71%, rgba(255,255,255,0) 100%); /* FF3.6+ */
                    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0.03)), color-stop(30%,rgba(255,255,255,0.85)), color-stop(50%,rgba(255,255,255,0.85)), color-stop(70%,rgba(255,255,255,0.85)), color-stop(71%,rgba(255,255,255,0.85)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
                    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
                    background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
                    background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* IE10+ */
                    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* W3C */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

                /*  forming the shine element
                    play around with the width, skew and gradient to get different effects
                  */
                    width: 15%;
                    height: 100%;
                     transform: skew(-10deg,0deg);
                    -webkit-transform: skew(-10deg,0deg);
                     -moz-transform: skew(-10deg,0deg);
                     -ms-transform: skew(-10deg,0deg);
                     -o-transform: skew(-10deg,0deg);

                /*  animating it  */
                     animation: move 2s;
                    animation-iteration-count: infinite;
                    animation-delay: 1s;
                    -webkit-animation: move 2s;
                    -webkit-animation-iteration-count: infinite;
                    -webkit-animation-delay: 1s;
                    -moz-transform: skew(-10deg,0deg);
                    -moz-animation: move 2s;
                    -moz-animation-iteration-count: infinite;
                    -moz-animation-delay: 1s;
                    -ms-transform: skew(-10deg,0deg);
                    -ms-animation: move 2s;
                    -ms-animation-iteration-count: infinite;
                    -ms-animation-delay: 1s;
                    -o-transform: skew(-10deg,0deg);
                    -o-animation: move 2s;
                    -o-animation-iteration-count: infinite;
                    -o-animation-delay: 1s;
                  }

                /*  */
                @keyframes move {
                  0%  { left: 0; opacity: 0; }
                  5% {opacity: 0.0}
                  48% {opacity: 0.2}
                  80% {opacity: 0.0}
                  100% { left: 82%}
                }

                @-webkit-keyframes move {
                  0%  { left: 0; opacity: 0; }
                  5% {opacity: 0.0}
                  48% {opacity: 0.2}
                  80% {opacity: 0.0}
                  100% { left: 82%}
                }

                @-moz-keyframes move {
                  0%  { left: 0; opacity: 0; }
                  5% {opacity: 0.0}
                  48% {opacity: 0.2}
                  80% {opacity: 0.0}
                  100% { left: 88%}
                }

                @-ms-keyframes move {
                  0%  { left: 0; opacity: 0; }
                  5% {opacity: 0.0}
                  48% {opacity: 0.2}
                  80% {opacity: 0.0}
                  100% { left: 82%}
                }

                @-o-keyframes move {
                  0%  { left: 0; opacity: 0; }
                  5% {opacity: 0.0}
                  48% {opacity: 0.2}
                  80% {opacity: 0.0}
                  100% { left: 82%}
                }

              &:hover {
                background-color: #1d314170;
                scale: 1.05;
                transition: 0.5s;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
                justify-content: center;
                i{
                  display: none;}

                .service-details {
                  opacity: 1;
                  transition: 0.5s;

                }

                svg {
                  display: none;
                }

                h3{
                  margin-bottom: 0%;
                }
              }
        
              .design_icon,
              .dev_icon,
              .maintenance_icon,
              .modeling_icon,
              .texturing_icon,
              .rendering_icon {
                filter: brightness(0) invert(1) drop-shadow(2px 4px 20px rgb(0, 0, 0) );
                min-height: 100px;
                min-width: 100px;
                max-width: 120px;
                max-height: 120px;
                background-size: cover;
              }
        
              h3 {
                margin: 0;
                color: #ffffff;
                font-size: calc(2.2vh/2 + 2.2vw/2);
                margin-bottom: 2%;
                font-weight: 500;
              }

              .service-details {
                margin-top: 10px;
                color: #ffffff;
                font-size: calc(1.5vh/2 + 1.5vw/2);
                background-color: rgba(0, 10, 14, 0.432);
                border-radius: 10px;
                padding: 10px;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
                text-align: left;
                width: 80%;
                height: 60%;
                margin-bottom: 1%;
                opacity: 0;

                li{
                  margin-bottom: 2%;
                }
              }
            }
          }
        
          .testimonials_container {
            display: none;
            justify-content: space-around;
            flex-wrap: wrap;
        
            .testimonial-item {
              background-color: #f9f9f9;
              border: 1px solid #ddd;
              padding: 20px;
              border-radius: 8px;
              text-align: center;
              margin: 10px;
              flex: 1 1 20%;
            }
          }
    }
}


@media screen and (min-width: 2000px) {
  .services {
      .services_box {
          .services_container {
              .service-item {
                  max-width: 50%;

                  h3{
                    font-size: calc(2vh/2 + 2vw/2);
                  }
              }
              .service-details{
                font-size: calc(1.2vh/2 + 1.2vw/2);
                li{
                  font-size: calc(1vh/2 + 1vw/2);}
              }
          }
      }
  }
}

@media screen and (max-width: 768px) {
  .services {
    width: 80%;

    h2 {
      font-size: calc(5vh/2 + 5vw/2);
    }

    .services_box {
      .services_container {
        .service-item {
          max-width: 100%;
          
          h3{
            font-size: calc(3.2vh/2 + 3.2vw/2);
          }

          .service-details{
            li{
              font-size: calc(2.2vh/2 + 2.2vw/2);
              margin-bottom: 6%;
            }
          }
        }
      }
    }
  }
  
}