.contact_section{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
        display: flex;
        font-size: calc(2.5vw / 2 + 2.5vh / 2);
        font-weight: 600;
        color: var(--white_text);
    }

    .email_and_phone{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        margin-top: 4%;
        margin-bottom: 3%;
        color: var(--white_text);

        .phone_article{
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            border-radius: 14px;
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.469);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            background-color: #01222c41;

            &:hover{
                box-shadow: inset 0 8px 20px rgba(0, 0, 0, 0.469);
                background-color: #00141a5d;
            }

            #phone_icon{
                background-image: url(../components/img/phone-call.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 25%;
                height: 100%;
            }

            .article_content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 60%;
                padding: 4%;

                .article_title{
                    font-size: calc(2.1vw / 2 + 2.1vh / 2);
                    font-weight: 500;
                    color: var(--white_text);
                    width: 80%;
                    text-align: center;
                }

                .article_link{
                    font-size: calc(1.4vw / 2 + 1.4vh / 2);
                    font-weight: 500;
                    color: var(--white_text);
                    width: 80%;
                    margin-top: 2%;
                    cursor: pointer;
                    font-style: italic;
                    text-align: center;
                    text-underline-offset: 4px;
                    margin-top: 5%;
                }
            }
        }

        .email_article{
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            border-radius: 14px;
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.469);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            background-color: #01222c41;

            &:hover{
                box-shadow: inset 0 8px 20px rgba(0, 0, 0, 0.469);
                background-color: #00141a5d;
            }

            #email_icon{
                background-image: url(../components/img/email.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 25%;
                height: 100%;
            }

            .article_content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 60%;
                padding: 4%;

                .article_title{
                    font-size: calc(2.1vw / 2 + 2.1vh / 2);
                    font-weight: 500;
                    color: var(--white_text);
                    width: 80%;
                    text-align: center;
                }

                .article_link{
                    font-size: calc(1.5vw / 2 + 1.5vh / 2);
                    font-weight: 500;
                    color: var(--white_text);
                    width: 80%;
                    margin-top: 2%;
                    cursor: pointer;
                    font-style: italic;
                    text-align: center;
                    text-underline-offset: 4px;
                    margin-top: 5%;
                }
            }
        
        }
    }

    .contact_form{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 2%;

        h3{
            font-size: calc(2.1vw / 2 + 2.1vh / 2);
            font-weight: 500;
            color: var(--white_text);
            width: 100%;
            text-align: center;
        }

        label{
            font-size: calc(1.8vw / 2 + 1.8vh / 2);
            font-weight: 500;
            color: var(--white_text);
            text-align: start;
        }

        input, textarea{
            padding: 10px;
            margin-top: 1%;
            margin-bottom: 2%;
            border-radius: 8px;
            border: 1px solid #ccc;
            box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.359);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            background-color: #01222c41;
            border: none;
            color: var(--white_text);
            font-size: calc(1.5vw / 2 + 1.5vh / 2);

            &::placeholder{
                color: rgb(216, 231, 233);
            }
        }

        textarea{
            resize: none;
        }

        button{
            padding: 10px 20px;
            margin-top: 2%;
            border-radius: 25px;
            background-color: #03313f2a;
            color: var(--white_text);
            font-size: 1.2rem;
            cursor: pointer;
            width: 50%;
            transition: all 0.3s ease;
            align-self: center;
            border: none;
            font-size: calc(2vw / 2 + 2vh / 2);
            font-weight: 500;

            box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.359);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);

            &:hover{
                background-color: #0a637fbd;
            }
        }
    }
}

@media (max-width: 768px) {
    .contact_section{
        width: 80%;

        h2{
            font-size: calc(5vw / 2 + 5vh / 2);
        }

        .email_and_phone{
            flex-direction: column;
            .phone_article, .email_article{
                width: 100%;
                margin-top: 5%;
                margin-bottom: 5%;

                #phone_icon, #email_icon{
                    width: 23%;
                }

                .article_content{
                    width: 70%;
                    padding: 3%;
                    .article_title{
                        font-size: calc(3.5vw / 2 + 3.5vh / 2);
                    }

                    .article_link{
                        font-size: calc(2.8vw / 2 + 2.8vh / 2);
                    }
                }
            }
        }

        .contact_form{

            h3{
                font-size: calc(3.5vw / 2 + 3.5vh / 2);
            }
            label{
                font-size: calc(3vw / 2 + 3vh / 2);
            }

            input, textarea{
                font-size: calc(3vw / 2 + 3vh / 2);
            }

            button{
                font-size: calc(3vw / 2 + 3vh / 2);
            }
        }
    }
}