.admin-panel-container {
    display: flex;
    height: 100vh;
    font-family: "Sarabun", sans-serif;
    width: 100%;
    justify-content: center;
    align-items: center;

    main{
        display: flex;
        width: 90%;

        height: 85vh;
        

        box-shadow: 0 8px 35px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border-radius: 30px;
        background-color: rgba(56, 67, 71, 0.231);

        .sidebar {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            height: 100%;
          
            .profile {
              text-align: center;
              height: 20%;
          
              img {
                border-radius: 50%;
                width: 100px;
                height: 100px;
              }
          
              h2 {
                margin-top: 10px;
                font-size: 18px;
                color: var(--white_text);
              }
            }
          
            .menu {
                width: 100%;
                height: 80%;
          
              ul {
                list-style: none;
                padding: 0;
                margin-top: 15%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 80%;

          
                li {
                  width: 80%;
                  margin-bottom: 10px;
          
                  button {
                    width: 100%;
                    padding: 4%;
                    background-color: transparent;
                    border: none;
                    text-align: left;
                    font-size: 16px;
                    color: var(--white_text);
                    cursor: pointer;
                    border-radius: 15px;
                    text-align: center;
                    box-shadow: 0 4px 30px #00000083;
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    transition: 0.4s ease;

          
                    &:hover {
                      background-color: #2244663b;
                    }
          
                    &.active {
                        background-color: #2244663b;
                        font-weight: bold;
                        box-shadow: inset 0 4px 20px #0000007a;
                    }
                  }
                }
              }
            }
          }
          
          section {
            flex: 1;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            height: 100%;
            width: 80%;
            display: flex;
            flex-direction: column;

            .title{
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: row;
                height: 10%;
                border-top-right-radius: 30px;

                h1{
                    font-size: 1.8rem;
                    font-weight: 600;
                    color: white;
                    width: 80%;
                    text-align: center;
                }

                button {
                    width: 12%;
                    padding: 1%;
                    background-color: #26445c52;
                    border: none;
                    text-align: left;
                    font-size: 16px;
                    color: var(--white_text);
                    cursor: pointer;
                    border-radius: 15px;
                    text-align: center;
                    box-shadow: 0 6px 30px #00000091;
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    transition: 0.4s ease;
    
                    &:hover {
                        background-color: #30567552;
                        box-shadow: inset 0 6px 30px #00000091;
                    }
                }
                
            }

            .rendered_page{
                width: 100%;
                height: 90%;
                background-color: #8695993b;
                border-bottom-right-radius: 30px;
                border-top-left-radius: 30px;
            }
          }
          
      }

}
