.portfolio_section {
    margin-top: 5%;
    width: 60%;

    h2 {
        font-size: calc(2.5vh/2 + 2.5vw/2);
        font-weight: 600;
        color: var(--white_text);
        text-align: center;
    }

    .projects_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin-top: 2%;

        h3 {
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--white_text);
            width: 48%;
            text-align: center;
            margin-bottom: 5%;
        }

        .projects_web {
            width: 48%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .projects_3d {
            width: 48%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    .portfolio-grid {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px;
    }

    .project-card {
        max-width: 39%;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        cursor: pointer;
        transition: transform 0.2s;
        display: flex;
        flex-direction: column;
        background-color: #ffffff0a;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.418);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;


        &:hover {
            transform: scale(1.05);
        }
        .project_title {
            width: 100%;
            max-height: 90%;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--white_text);
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .project-card img {
        max-width: 90%;
        height: auto;
        border-radius: 6px;
        vertical-align: middle;
    }

    .projects_web {
        margin-top: 2%;
    }

    .projects_3d {
        margin-top: 2%;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;

    &.is-open {
        opacity: 1;
        visibility: visible;
    }

    .modal-content {
        background-color: rgba(86, 115, 124, 0.082);
        padding: 20px;
        border-radius: 8px;
        max-width: 80vw;
        max-height: 80vh;
        height: 90%;
        width: 90%;
        position: relative;
        box-shadow: 0 15px 50px rgba(0, 0, 0, 0.651);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        
        span{
            color: white;
            font-size: calc(2.8vw / 2 + 2.8vh / 2);
            font-weight: bold;
            scale: 1.1;

            &:hover{
                scale: 1.3;
                transition: 0.5s;
            }
        }

        h2 {
            font-size: 1.8rem;
            margin-bottom: 10px;
            color: var(--white_text);
        }

        p {
            font-size: 1rem;
            margin-bottom: 10px;
            color: var(--white_text);
        }

        .image-gallery {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            img {
                max-width: 90%;
                max-height: 90%;
                width: auto;
                height: 100px;
                border-radius: 15px;
                cursor: pointer;

                &.large-image {
                    width: 90%;
                    height: auto;
                    max-height: 60vh;
                    object-fit: contain;
                }
            }

            &.web-project {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;

    .image-modal-content {
        background-color: rgba(86, 115, 124, 0.082);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 15px 50px rgba(0, 0, 0, 0.651);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        border-radius: 8px;
        width: 78%;
        height: 73%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span{
            color: white;
            font-size: calc(3.5vw / 2 + 3.5vh / 2);
            font-weight: bold;

            scale: 1.1;

            &:hover{
                scale: 1.3;
                transition: 0.5s;
            }
        }

        img {
            max-width: 90%;
            max-height: 90%;
            width: 90%;
            height: auto;
            border-radius: 15px;

        }

        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
}

.modal-open {
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .portfolio_section {
        width: 90%;

        h2 {
            font-size: calc(5vh/2 + 5vw/2);
          }

        .projects_container {
            flex-direction: column;
            gap: 6vh;
            margin-bottom: 10%;

            h3 {
                width: 100%;
            }

            .projects_web {
                width: 100%;
            }

            .projects_3d {
                width: 100%;
            }
            .project-card {
                max-width: 38%;
            }
        }
    }
}

@media screen and (min-width: 1700px) {
    .portfolio_section {

        .image-modal-content {

            img {
                max-width: 90%;
                max-height: 90%;
                width: auto;
                height: 95%;
                border-radius: 15px;
            }
        }
    }
}


