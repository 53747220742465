:root {
    

    --background: #0b1c2a;
    --background-dark: #192325;

    --navbar: #64a7b71f;

    --white_text: #f2f8fa;
    --black_text: #032230;

    --hover_text: #000f11e3;

    --primary-color: #2E5A8B;
    --primary-color-dark: #224567;

    --secondary-color: #FFC107;
    --secondary-color-dark: #FFA000;

    --white: #ffffff;

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

html{
    background: rgb(2,23,38);
    font-family: "Sarabun", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;

    background: rgb(1, 19, 32);
    background: linear-gradient(0deg, rgb(1, 15, 26) 0%, rgb(3, 48, 66) 100%);

    ::-webkit-scrollbar {
        width: 10px;
        background-color: #00000000;
    }
    ::-webkit-scrollbar-track {
        background: linear-gradient(0deg, rgb(1, 15, 26) 0%, rgb(5, 63, 85) 100%);
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #0f777d;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #025155;
    }


    body{
        height: 100%;
    }
}

#root{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;

    .bouncing-blob {
        width: 45vw;
        aspect-ratio: 1;
        border-radius: 50%;
        will-change: transform;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        transform-origin: left top;
      }
      
      .bouncing-blob--blue {
        background: #2d607e;
      }
      
      .bouncing-blob--white {
        background: #48615f;
        z-index: 2;
        width: 15vw;
      }
      
      .bouncing-blob--purple {
        background: #3b3d68;
      }
      
      .bouncing-blob--pink {
        background: #814d7550;
      }
      
      .bouncing-blobs-container {
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      
      .bouncing-blobs-glass {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(270px);
        -webkit-backdrop-filter: blur(257px);
        pointer-events: none;
      }
      
      .bouncing-blobs {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
      }


}

@media (max-width: 768px) {
    #root{
      .bouncing-blobs{
        display: none;
      }
        .bouncing-blob {
            width: 60vw;
          }
          .bouncing-blob--white {
            width: 40vw;
          }
          .bouncing-blobs-glass {
            backdrop-filter: blur(160px);
            -webkit-backdrop-filter: blur(160px);
          }
    }
}