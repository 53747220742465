// src/styles/AdminUpdateInfo.scss
.update-info {

    .update-content {
        padding: 20px;

        .update-form {
            margin-top: 20px;

            h2 {
                font-size: 20px;
                margin-bottom: 10px;
                color: #ffffff;
            }

            button {
                margin-bottom: 20px;
                padding: 1.5%;
                font-size: 14px;
                color: #fff;
                background-color: #0196bf90;
                border: none;
                border-radius: 10px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #0056b3;
                }
            }

            ul {
                list-style-type: none;
                padding: 0;
                overflow-y: scroll;
                max-height: 55vh;
                width: 60%;

                li {
                    padding: 1%;
                    margin-bottom: 5px;
                    background-color: #f9f9f944;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: white;
                    font-size: calc(1.5vw / 2 + 1.5vh / 2);


                    button {
                        padding: 5px 10px;
                        font-size: 12px;
                        color: #fff;
                        background-color: #10556b;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: background-color 0.3s ease;
                        font-size: calc(1.5vw / 2 + 1.5vh / 2);
                        vertical-align: middle;

                        &:hover {
                            background-color: #052d3b;
                        }

                        &.delete-button {
                            background-color: #dc3545;

                            &:hover {
                                background-color: #c82333;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Styles for the modal
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.modal.is-open {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    background: #fff;
    padding: 20px;
    position: relative;
    width: 100%;
    max-width: 500px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

    form {
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 5px;
            font-weight: bold;
        }

        input[type="text"], textarea {
            margin-bottom: 15px;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        textarea {
            resize: vertical;
        }

        button {
            padding: 10px 15px;
            font-size: 14px;
            color: #fff;
            background-color: #007bff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #0056b3;
            }

            &.delete-button {
                background-color: #dc3545;
                margin-top: 10px;

                &:hover {
                    background-color: #c82333;
                }
            }
        }
    }

    .modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
    }
}
