.popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 250px;
    background-color: #313d4e;  // Couleur de fond pour correspondre au thème sombre
    border: 1px solid #0d1b2a;  // Bordure subtile pour respecter les tons sombres
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);  // Ombre pour la profondeur
    
    .popup-content {
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      p {
        color: #ffffff;  // Texte blanc pour ressortir sur le fond sombre
        font-size: 15px;
        margin-bottom: 10px;
        width: 90%;
        text-align: center;
      }
  
      .popup-button {
        padding: 10px 20px;
        background-color: #007bff;  // Couleur bleue pour rappeler les éléments interactifs
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.5s ease-in-out;
        
        &:hover {
          background-color: #0056b3;  // Bleu foncé au hover pour un meilleur contraste
          scale: 1.1;
        }
      }
  
      .popup-close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: #aaa;
        transition: color 0.3s ease;
  
        &:hover {
          color: #ff0000;  // Rouge lors du hover pour fermer la popup
        }
      }
    }
  }
  