/*PortfolioManagement*/
.portfolio-management {
    display: flex;
    gap: 20px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;

    .add_project{
        padding: 1%;
        width: 25%;
        background-color: #053547;
        color: var(--white_text);
        border: none;
        cursor: pointer;
        border-radius: 10px;
        font-size: calc(2vh / 2 + 2vw / 2);
        transition: background-color 0.3s;
        margin-top: 2%;

        &:hover{
            background-color: #011b24;
        }
    }

  .project-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow-y: scroll;
    max-height: 55vh;
    width: 90%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    box-shadow: inset 0 8px 30px rgba(0, 0, 0, 0.359);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 2%;
    border-radius: 15px;

  }
  
  /*ProjectForm*/
  .project-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .project-form input,
  .project-form textarea {
    padding: 10px;
    font-size: 16px;
  }
  
  /*ProjectItem*/
  .project-item {
    border: 1px solid #dddddd6a;
    background-color: #121a1d50;
    padding: 20px;
    width: 300px;
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.359);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: white;

    h3{
      font-size: calc(1.5vw / 2 + 1.5vh / 2);
    }

    p{
      font-size: calc(1vw / 2 + 1vh / 2);
      margin-bottom: 1%;
      margin-top: 2%;
    }

    button{
      padding: 2%;
      font-size: calc(1.3vw / 2 + 1.3vh / 2);
      background-color: #053547;
      color: var(--white_text);
      border: none;
      cursor: pointer;
      border-radius: 10px;
      transition: background-color 0.3s;
      margin-top: 2%;
      width: 40%;
      transition: 0.4s;
      margin: 2%;

      &:hover{
        background-color: #01141a;
        scale: 1.05;
      }
    }
  }
  
  .project-item img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
  }
  
  /*Modal*/
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .modal.is-open {
    opacity: 1;
    visibility: visible;
    width: 50%;
  }
}

.admin_modal_content {
  background-color: rgba(86, 115, 124, 0.082);
  padding: 20px;
  border-radius: 8px;
  max-width: 80vw;
  max-height: 80vh;
  height: 90%;
  width: 50%;
  position: relative;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.651);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  color: white;

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
    font-size: calc(1.5vw / 2 + 1.5vh / 2);
    font-weight: bold;

    &:hover{
      scale: 1.3;
      transition: 0.5s;
      color: #676c6e;
  }
  }

  form {
      display: flex;
      flex-direction: column;

      label {
          margin-bottom: 5px;
          font-weight: bold;
      }

      input[type="text"], textarea {
          margin-bottom: 15px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
      }

      textarea {
          resize: vertical;
      }

      button {
          padding: 10px 15px;
          font-size: 14px;
          color: #fff;
          background-color: #007bff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
              background-color: #0056b3;
          }

          &.delete-button {
              background-color: #dc3545;
              margin-top: 10px;

              &:hover {
                  background-color: #c82333;
              }
          }
      }
  }
}