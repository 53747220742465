.main_footer{
    height: 6vh;
    padding: 0.6%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    background-color: var(--navbar);
    border-radius: 30px;
    margin-bottom: 1%;
    width: 30%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.359);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);


    .linkedin_logo{
        background-image: url(../components/img/linkedin.svg);
        filter: brightness(0) invert(1);
        width: 30px;
        max-width: 30px;
        height: 30px;
        max-height: 30px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    div, h4, .terms{
        font-size: calc(1.5vw / 2 + 1.5vh / 2);
        font-weight: 500;
        margin-bottom: 1%;
        color: var(--white_text);
    }

    h4{
        margin-left: 1%;
    }

    div{
        width: 100%;
        text-align: center;
    }

}

@media (max-width: 768px) {
    .main_footer{
        width: 90%;
        height: 9vh;
        border-radius: 25px;

        div, .terms{
            font-size: calc(2.5vw / 2 + 2.5vh / 2);
        }

        h4{
            text-align: center;
            margin-top: 4%;
            font-size: calc(3vw / 2 + 3vh / 2);
        }
        a{
            margin-top: 3%;
        }
    }
}