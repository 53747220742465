.about_section {
    margin-top: 5%;
    color: var(--white_text);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    

    h2 {
        font-size: calc(2.5vh/2 + 2.5vw/2);
        font-weight: 600;
        margin-bottom: 20px;
    }

    .about_text {
        p {
            margin-top: 1%;
            font-size: calc(1.8vh/2 + 1.8vw/2);
            text-align: justify;
            line-height: 1.05;
        }
    }

    h3 {
        font-size: calc(2.2vh/2 + 2.2vw/2);
        font-weight: 500;
        margin-bottom: 10px;
        margin-top: 4%;
    }

    .about_skills {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
        position: relative; // Create a new stacking context

        .dev_skills, .others_skills {
            width: 48%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            list-style: none;
            padding: 0;
            margin-top: 2%;
            font-size: 1.5rem;
            font-weight: 500;

            h4 {
                font-size: calc(1.85vw / 2 + 1.85vh / 2);
                font-weight: 500;
                margin-bottom: 3%;
                width: 100%;
                text-align: center;
            }

            .skill {
                position: relative;
                margin-top: 3%;
                margin-bottom: 3%;
                padding: 3%;
                border-radius: 25px;
                background-color: #04242e52;
                transition: all 0.3s ease;
                cursor: pointer;
                box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.359);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                font-size: calc(1.75vw / 2 + 1.75vh / 2);
                width: 35%;
                text-align: center;

                p {
                    margin: 0;
                }

                &:hover {
                    background-color: #021920bd;
                    box-shadow: 0 7px 16px rgba(0, 0, 0, 0.47);
                }
            }
        }

        .description {
            position: absolute;
            background-color: rgba(1, 20, 30, 0.507);
            color: #fff;
            padding: 10px;
            border-radius: 5px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            font-size: 0.9rem;
            text-align: center;
            z-index: 10;
            opacity: 1;
            max-width: 50%;
        }
    }
}

@media (max-width: 768px) {
    .about_section {
        width: 80%;

        h2 {
            font-size: calc(5vh/2 + 5vw/2);
          }

        .about_text {
            p {
                text-align: justify;
                font-size: calc(3vh/2 + 3vw/2);
            }
            margin-bottom: 5%;
        }

        h3 {
            font-size: calc(4vh/2 + 4vw/2);
        }

        .about_skills {
            flex-direction: column;
            align-items: center;
            margin-bottom: 7%;

            .dev_skills, .others_skills {
                width: 100%;

                h4 {
                    font-size: calc(3.4vw / 2 + 3.4vh / 2);
                }

                .skill {
                    width: 40%;
                    font-size: calc(3vw / 2 + 3vh / 2);
                }
            }
        }
    }
}
