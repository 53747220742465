.welcome_section {
    margin-top: 12%;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    color: var(--white_text);
    width: 60%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;


    .welcome_container{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 5%;

        .welcome_section_01 {
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;

            .profile_picture{
                background-image: url(../components/img/neodev3d.webp);
                max-width: 220px;
                height: 220px;
                max-height: 220px;
                width: 220px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        .welcome_section_02 {
            width: 65%;
            margin-left: 20px;
            font-size: calc(2vh/2 + 2vw/2);
            font-weight: 400;
            color: var(--white_text);
            line-height: 1.25;
        }
    }

    .welcome_section_02 {
        font-size: 1.3rem;
        text-align: justify;
    }
}

.footer_space{
    height: 2vh;
    padding: 0.1%;
  }


@media (max-width: 768px) {
    .welcome_section{
        margin-top: 25%;
        width: 80%;
        .welcome_container{
            margin-top: 0%;
            width: 100%;
            flex-direction: column;

            .welcome_section_01{
                width: 100%;
                margin-bottom: 6%;
            }
            .welcome_section_02{
                width: 100%;
                margin-left: 0px;
                text-align: justify;
                font-size: calc(3.5vh/2 + 3.5vw/2);
            }
        }
    }

    .footer_space{
        height: 2vh;
        padding: 1%;
      }
}