// ThreeView.scss

.three-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95%;
    width: 100%;

    .three-view-canvas {
      border: 3px solid #044e64;
      height: 100%;
      border-radius: 20px;
    }

    .three-view-background {
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, #2031315e, #0000009a);
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
  