#root{
    height: 100vh;}

.login_page{
    width: 100%;
    height: 100%;

    .login_container{
        background: rgba(97, 112, 124, 0.13);
        border-radius: 20px;
        padding: 20px;
        max-width: 40%;
        margin: 50px auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.062);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.158);
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2{
            font-size: calc(2.5vw / 2 + 2.5vh / 2);
            font-weight: 600;
            color: #ffffff;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            padding: 2%;
        }

    
        #login-form {
            width: 90%;
            height: 80%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
          
            div {
              margin-bottom: 15px;
                width: 80%;
            }
          
            label {
              display: block;
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 5px;
              color: #ffffff;
              text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            }
          
            input {
              width: 90%;
              padding: 10px;
              border-radius: 5px;
              border: 1px solid rgba(255, 255, 255, 0.2);
              background: rgba(255, 255, 255, 0.068);
              color: #ffffff;
              box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
              backdrop-filter: blur(5px);
              font-size: calc(1.5vw / 2 + 1.5vh / 2);
          
              &:focus {
                outline: none;
                border-color: rgba(255, 255, 255, 0.5);
                box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
              }
            }
          
            button {
              width: 50%;
              padding: 10px;
              border: none;
              background: rgba(255, 255, 255, 0.048);
              color: #ffffff;
              font-size: calc(1.8vw / 2 + 1.8vh / 2);
              font-weight: bold;
              cursor: pointer;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              backdrop-filter: blur(5px);
              transition: background 0.3s ease;
              border-radius: 15px;
          
              &:hover {
                background: rgba(255, 255, 255, 0.11);
              }
          
              &:disabled {
                cursor: not-allowed;
                background: rgba(255, 255, 255, 0.2);
              }
            }
          
            p {
              color: red;
              font-size: calc(1.5vw / 2 + 1.5vh / 2);
            }

        }
    
    }
      

}

