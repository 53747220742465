/* src/styles/ContactManagement.scss */
.contact-management {
    padding: 20px;
}

.contact-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
        font-size: 2rem;
        font-weight: 600;
    }
}

.management {
    .contact-content {
        margin-top: 20px;

        h2 {
            font-size: 1.5rem;
            font-weight: 500;
        }

        .message-item {
            border: 1px solid #ddd;
            padding: 15px;
            margin-bottom: 15px;
            border-radius: 5px;

            h3 {
                margin: 0;
                margin-bottom: 10px;
                font-size: 1.2rem;
                font-weight: 500;
            }

            p {
                margin: 5px 0;

                strong {
                    font-weight: 600;
                }
            }
        }
    }
}
