.dashboard_container{
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: #32353800;


    .dashboard_content{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-around;
        background-color: #32353800;

        .statistics{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: 100%;
            height: 65%;
            background-color: #32353800;

        }
        .chart{
            height: 35%;
            background-color: #32353800;
        }
    }
}