.terms_page{
    margin-top: 8%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .back_btn{
        color: white;
        font-size: 1.8rem;
        background-color: rgba(0, 0, 0, 0.195);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.551);
            backdrop-filter: blur(50px);
            -webkit-backdrop-filter: blur(50px);
        border-radius: 25px;
        border: 0;
        padding: 1%;
        margin: 1%;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover{
            box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.312);
            scale: 1.06;
        }
    }

    .terms{
        width: 80%;
        padding: 3%;
        background-color: rgba(0, 0, 0, 0.195);
        box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.268);
            backdrop-filter: blur(50px);
            -webkit-backdrop-filter: blur(50px);
        border-radius: 35px;
        margin-bottom: 2%;

        h1{
            font-size: 2.5rem;
            font-weight: 700;
            margin-bottom: 2%;
            text-align: center;
            margin-bottom: 8%;
            text-decoration: underline;
        }
        h2{
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 2%;
        }
        p{
            font-size: 1.5rem;
            margin-bottom: 2%;
            margin-left: 1%;
            color: rgb(184, 184, 199);
            font-style: italic;
            line-height: 150%;
        }
        a{
            color: white;
            text-decoration: underline;
        }
    }
    

}